<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="手机号" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入手机号" />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email" >
        <a-input v-model="form.email" placeholder="请输入邮箱" />
      </a-form-model-item>
      <a-form-model-item label="密码，md5" prop="password" >
        <a-input v-model="form.password" placeholder="请输入密码，md5" />
      </a-form-model-item>
      <a-form-model-item label="头像链接" prop="avatar" >
        <a-input v-model="form.avatar" placeholder="请输入头像链接" />
      </a-form-model-item>
      <a-form-model-item label="昵称" prop="nickname" >
        <a-input v-model="form.nickname" placeholder="请输入昵称" />
      </a-form-model-item>
      <a-form-model-item label="性别,0-保密 1-男 2-女" prop="sex" >
        <a-select placeholder="请选择性别,0-保密 1-男 2-女" v-model="form.sex">
          <a-select-option v-for="(d, index) in sexOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="生日，格式1990-04-22" prop="birthday" >
      </a-form-model-item>
      <a-form-model-item label="用户个性签名" prop="userSign" v-if="formType === 1">
        <a-input v-model="form.userSign" placeholder="请输入用户个性签名" />
      </a-form-model-item>
      <a-form-model-item label="邀请码" prop="invitationCode" >
        <a-input v-model="form.invitationCode" placeholder="请输入邀请码" />
      </a-form-model-item>
      <a-form-model-item label="是否推送 1-是 0-否" prop="isNotice" >
        <a-select placeholder="请选择是否推送 1-是 0-否" v-model="form.isNotice">
          <a-select-option v-for="(d, index) in isNoticeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录" prop="status" >
        <a-select placeholder="请选择用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录" v-model="form.status">
          <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" v-if="formType === 1">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUser, addUser, updateUser } from '@/api/biz/user'

export default {
  name: 'CreateForm',
  props: {
    sexOptions: {
      type: Array,
      required: true
    },
    isNoticeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        mobile: null,

        email: null,

        password: null,

        avatar: null,

        nickname: null,

        sex: null,

        birthday: null,

        userSign: null,

        invitationCode: null,

        isNotice: null,

        status: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        mobile: [
          { required: true, message: '手机号不能为空' }
        ],
        nickname: [
          { required: true, message: '昵称不能为空' }
        ],
        isNotice: [
          { required: true, message: '是否推送 1-是 0-否不能为空', trigger: 'change' }
        ],
        status: [
          { required: true, message: '用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        mobile: null,
        email: null,
        password: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUser({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
